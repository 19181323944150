import { AppRouter } from "./components/app-router/app-router.component";
import { Layout } from "./components/main-layout/layout/layout.component";
import { Header } from "./components/main-layout/header/header.component";
import { MainContent } from "./components/main-layout/main-content/main-content.component";
import { Footer } from "./components/main-layout/footer/footer.component";
import { useMediaQuery } from "./utils/use-media-query";
import { useEffect, useState } from "react";
import { useScrollToTop } from "./utils/use-scroll-to-top";
import { useDispatch, useSelector } from "./redux/store";
import operations from "./redux/uiData/operations";
import { uiDataFetching, uiDataSelectedLanguageId } from "./redux/uiData/selectors";
import Spinner from "./components/main-layout/spinner/spinner.component";
import { setSelectedLanguage } from "./redux/uiData/slice";

const App = () => {
  const language = useSelector(uiDataSelectedLanguageId);
  const loading = useSelector(uiDataFetching);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("xs");
  const [initalUpdated, setInitialUpdated] = useState(false);

  useEffect(() => {
    //  fix if mobile menu open and resolution changed
    if (!isMobile && document.body.style.overflow !== "auto") {
      document.body.style.overflow = "auto";
    }
  }, [isMobile]);

  useEffect(() => {
    dispatch(operations.getAllWebsiteText(language));
    dispatch(operations.getMediaCenterInvestorNews({ languageId: language }));
    dispatch(operations.getMediaCenterNews({ languageId: language }));
    dispatch(operations.getAboutUsFinanStats({ languageId: language }));
    dispatch(operations.getAboutUsFinanDropDown({ languageId: language }));
    dispatch(operations.getCountryOfResidence({ languageId: language }));
    dispatch(operations.getGlobalOfficeDropDown({ languageId: language }));
    dispatch(operations.getScripts());
    dispatch(operations.getCareerDropDown({ languageId: language }));
    dispatch(operations.getAboutUsLeadership({ languageId: language }));
    dispatch(operations.getCardBenefitData({ languageId: language }));

    if (language && initalUpdated) {
      window.localStorage.setItem("SELECTED_LANGUAGE", language);
    }
    setInitialUpdated(true);
  }, [language]);
  useEffect(() => {
    dispatch(operations.getLanguages());
    const persistedLanguage = window.localStorage.getItem("SELECTED_LANGUAGE");
    if (!!persistedLanguage && persistedLanguage !== "undefined") {
      dispatch(setSelectedLanguage({ id: persistedLanguage }));
    }
  }, []);

  useScrollToTop();
  return (
    <Layout>
      {loading && <Spinner />}
      {!loading && (
        <>
        <Header />
      <MainContent>
        <AppRouter />
      </MainContent>
      <Footer />
        </>
      )}
    </Layout>
  );
};

export default App;
