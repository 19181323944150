import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  WebsiteTextType,
  SiteContentMediaCenterInvestorNews,
  SiteContentMediaCenterNews,
  AboutUsFinanStats,
  // GlobalOfficeType,
  CareerJobs,
  AboutUsFinanDropDown,
  LeadershipExecutiveData,
  LeadershipPersonData,
  LeadershipBoardData,
  LeadershipAllData,
  ResAllLeadershipData as AllLeadershipData,
  CardBenefitData,
} from "../../api/types/fetch.ui.types";
import { SelectOptions } from "../../components/inputs/select/select.types";
import operations from "./operations";
import _ from "lodash";
import { Base64 } from "js-base64";

type InitialStateData = {
  loading: boolean;
  languageList: SelectOptions | null;
  globalOffices: any[] | null;
  countryOfResidence: {} | null;
  globalOfficeDropDown: {} | null;
  scripts: {} | null;
  careerDropDown: Record<string, SelectOptions> | null;
  mediaCenterInvestorNewsData: SiteContentMediaCenterInvestorNews[] | null;
  mediaCenterNewsData: SiteContentMediaCenterNews[] | null;
  selectedLanguageId: string | null;
  websiteText: WebsiteTextType;
  aboutUsLeadership: AllLeadershipData | null;
  aboutUsLeadershipPerson: LeadershipPersonData[] | null;
  aboutUsFinanStats: AboutUsFinanStats[] | null;
  aboutUsFinanDropDown: AboutUsFinanDropDown | null;
  careerJobs: CareerJobs[] | null;
  cardBenefits: CardBenefitData[] | null;
};
const initialState: InitialStateData = {
  loading: false,
  languageList: null,
  globalOffices: null,
  countryOfResidence: null,
  globalOfficeDropDown: null,
  scripts: null,
  careerDropDown: { abtus_cr_day: null, abtus_cr_cntry: null },
  mediaCenterInvestorNewsData: null,
  mediaCenterNewsData: null,
  selectedLanguageId: "1",
  websiteText: {
    common: {
      hf_head_one_rt: "",
      hf_head_one_rt_subone: "",
      hf_head_one_rt_subone_sub: "",
      hf_head_one_rt_subtwo: "",
      hf_head_one_rt_subtwo_sub: "",
      hf_head_one_rt_subthree: "",
      hf_head_one_rt_subthree_sub: "",
      hf_head_two_rt: "",
      hf_head_two_rt__subone: "",
      hf_head_two_rt__subone_sub: "",
      hf_head_two_rt__subtwo: "",
      hf_head_two_rt__subtwo_sub: "",
      hf_head_two_rt_subthree: "",
      hf_head_two_rt_subthree_sub: "",
      hf_head_three_rt: "",
      hf_head_three_rt_subone: "",
      hf_head_three_rt_subone_sub: "",
      hf_head_three_rt__subtwo: "",
      hf_head_three_rt__subtwo_sub: "",
      hf_head_three_rt_subthree: "",
      hf_head_three_rt_subthree_sub: "",
      hf_head_four_lft: "",
      hf_head_five_lft: "",
      hf_foot_left_headOne: "",
      hf_foot_left_lineTwo: "",
      hf_foot_mid_headOne: "",
      hf_foot_mid_headOne_lineOne: "",
      hf_foot_mid_headOne_lineTwo: "",
      hf_foot_mid_headOne_lineFour: "",
      hf_foot_right_lineTwo: "",
      hf_foot_right_headOne: "",
      hf_head_one_rt_subfour: "",
      hf_head_one_rt_subfour_sub: "",
      hf_head_one_rt_subfive: "",
      hf_head_one_rt_subfive_sub: "",
      hf_head_one_rt_subsix: "",
      hf_head_one_rt_subsix_sub: "",
      hf_head_one_rt_subseven: "",
      hf_head_one_rt_subseven_sub: "",
      hf_head_one_rt_subeight: "",
      hf_head_one_rt_subeight_sub: "",
      hf_head_one_rt_subnine: "",
      hf_head_one_rt_subnine_sub: "",
      hf_foot_left_lineFour: "",
      hf_foot_left_lineFive: "",
      hf_foot_left_lineSix: "",
      hf_foot_left_lineSeven: "",
      hf_foot_left_lineEight: "",
      hf_foot_left_lineNine: "",
      hf_foot_mid_headOne_lineThree: "",
      hf_foot_mid_headOne_lineFive: "",
      hf_foot_mid_headOne_lineSix: "",
      hf_foot_mid_headOne_lineSeven: "",
      hf_foot_mid_headOne_lineEight: "",
      hf_foot_mid_headOne_lineNine: "",
      hf_foot_mid_headTwo: "",
      hf_foot_right_lineThree: "",
      hf_foot_mid_headTwo_lineOne: "",
      hf_foot_mid_headTwo_lineTwo: "",
      hf_foot_mid_headTwo_lineThree: "",
      hf_foot_mid_headTwo_lineFour: "",
      hf_foot_left_lineOne: "",
      hf_foot_left_lineTen: "",
      hf_foot_left_lineEleven: "",
      hf_foot_left_lineTwelve: "",
      hf_foot_twitter_link: "",
      hf_foot_instagram_link: "",
      hf_foot_right_lineFour: "",
      hf_foot_right_lineFive: "",
      hf_foot_right_lineSix: "",
      hf_foot_right_lineSeven: "",
      metaData: "",
    },
    homePage: {
      hp_head_left: "",
      hp_head_left_sub: "",
      hp_head_mid: "",
      hp_head_mid_sub: "",
      hp_head_right: "",
      hp_head_right_sub: "",
      hp_sectionOne_head: "",
      hp_sectionOne_text: "",
      hp_sectionOne_textlink: "",
      hp_sectionTwo_head: "",
      hp_sectionTwo_text: "",
      hp_sectionTwo_textlink: "",
      hp_sectionThree_head: "",
      hp_sectionThree_text: "",
      hp_sectionThree_textlink: "",
      hp_login_link: "",
      hp_signup_link: "",
      metaData: "",
    },
    aboutUsMission: {
      abtus_misn_headOne: "",
      abtus_misn_subheadOne: "",
      abtus_misn_subheadOne_ParaOne: "",
      abtus_misn__subheadOne_ParaTwo: "",
      abtus_misn__subheadOne_ParaThree: "",
      abtus_misn__subheadOne_ParaFour: "",
      abtus_misn_headTwo: "",
      abtus_misn_headTwo_ParaOne: "",
      abtus_misn_headTwo_ParaTwo: "",
      abtus_misn_headTwo_ParaThree: "",
      abtus_misn_headTwo_ParaFour: "",
      abtus_misn_headTwo_ParaFive: "",
      abtus_misn_headTwo_ParaSix: "",
      abtus_misn_headTwo_ParaSeven: "",
      abtus_misn_headThree: "",
      abtus_misn_headThreeSub: "",
      abtus_misn_headThree_ColOne_RowOne: "",
      abtus_misn_headThree_ColOne_RowTwo: "",
      abtus_misn_headThree_ColOne_RowThree: "",
      abtus_misn_headThree_ColTwo_RowOne: "",
      abtus_misn_headThree_ColTwo_RowTwo: "",
      abtus_misn_headThree_ColTwo_RowThree: "",
      abtus_misn_headThree_ColOne_RowOne_nxt: "",
      abtus_misn_headThree_ColOne_RowTwo_nxt: "",
      abtus_misn_headThree_ColOne_RowThree_nxt: "",
      abtus_misn_headThree_ColTwo_RowOne_nxt: "",
      abtus_misn_headThree_ColTwo_RowTwo_nxt: "",
      abtus_misn_headThree_ColTwo_RowThree_nxt: "",
      abtus_misn_headThree_ParaHeadOne: "",
      abtus_misn_headThree_ParaHeadOne_txtone: "",
      abtus_misn_headThree_ParaHeadTwo: "",
      abtus_misn_headThree_ParaHeadTwo_txtone: "",
      abtus_misn_headThree_ParaHeadThree: "",
      abtus_misn_headThree_ParaHeadThree_txtone: "",
      abtus_misn_headThree_ParaHeadFour: "",
      abtus_misn_headThree_ParaHeadFour_txtone: "",
      abtus_misn_headThree_ParaHeadFive: "",
      abtus_misn_headThree_ParaHeadFive_txtone: "",
      abtus_misn_headThree_ParaHeadSix: "",
      abtus_misn_headThree_ParaHeadSix_txtone: "",
      abtus_misn_headThree_ParaHeadSeven: "",
      abtus_misn_headThree_ParaHeadSeven_txtone: "",
      abtus_misn_headThree_ParaHeadEight: "",
      abtus_misn_headThree_ParaHeadEight_txtone: "",
      abtus_misn_headThree_ParaHeadNine: "",
      abtus_misn_headThree_ParaHeadNine_txtone: "",
      abtus_misn_headThree_ParaHeadTen: "",
      abtus_misn_headThree_ParaHeadTen_txtone: "",
      abtus_misn_headThree_ParaHeadEleven: "",
      abtus_misn_headThree_ParaHeadEleven_txtone: "",
      abtus_misn_headThree_ParaHeadTwelve: "",
      abtus_misn_headThree_ParaHeadTwelve_txtone: "",
      abtus_misn_headFour: "",
      abtus_misn_headFour_ParaOne: "",
      abtus_misn_headFour_ParaTwo: "",
      abtus_misn_subheadFour_One: "",
      abtus_misn_subheadFour_One_txtone: "",
      abtus_misn_subheadFour_Five_txtfour_sub: "",
      abtus_misn_subheadFour_Two: "",
      abtus_misn_subheadFour_Two_txtone: "",
      abtus_misn_subheadFour_Two_txttwo: "",
      abtus_misn_subheadFour_Four_txtthree: "",
      abtus_misn_subheadFour_Five_txtfour: "",
      abtus_misn_subheadFour_Six_txtfive: "",
      abtus_misn_headFive: "",
      // abtus_misn_headFive: "",
      abtus_misn_headFive_ParaHeadOne: "",
      abtus_misn_headFive_ParaHeadOne_txtone: "",
      abtus_misn_headFive_ParaHeadTwo: "",
      abtus_misn_headFive_ParaHeadTwo_txtone: "",
      abtus_misn_headFive_ParaHeadThree: "",
      abtus_misn_headFive_ParaHeadThree_txtone: "",
      abtus_misn_headFive_ParaHeadFour: "",
      abtus_misn_headFive_ParaHeadFour_txtone: "",
      abtus_misn_headFive_ParaOne: "",
      abtus_misn_headFive_ParaTwo: "",
      abtus_misn_subheadFour_Five_txtfour_artone: "",
      abtus_misn_subheadFour_Five_txtfour_arttwo: "",
      abtus_misn_subheadFour_Five_txtfour_artthree: "",
      abtus_misn_subheadFour_Five_txtfour_artfour: "",
      abtus_misn_subheadFour_Five_txtfour_artfive: "",
      abtus_misn_subheadFour_Five_txtfour_artsix: "",
      abtus_misn_subheadFour_Five_txtfour_artseven: "",
      abtus_misn_subheadFour_Five_txtfour_arteight: "",
      abtus_misn_headFive_ParaHeadOne_txttwo: "",
      abtus_misn_headFive_ParaHeadFour_txttwo: "",
      abtus_misn_headFive_ParaHeadThree_txttwo: "",
      abtus_misn_headFive_ParaHeadTwo_txttwo: "",
      metaData: "",
    },
    aboutUsSecurity: {
      abtus_scrty_headOne: "",
      abtus_scrty_left_HeadOne: "",
      abtus_scrty_HeadTwoSub: "",
      abtus_scrty_left_HeadOne_Paraone: "",
      abtus_scrty_right_HeadOne: "",
      abtus_scrty_right_HeadOne_ParaOne: "",
      metaData: "",
      abtus_scrty_right_subhead: "",
      abtus_scrty_headSub: "",
    },
    aboutUsCareers: {
      abtus_cr_benefit_nxt: "",
      abtus_cr_mainhead: "",
      abtus_cr_mainhead_nxt: "",
      abtus_cr_openaccount: "",
      abtus_cr_sectionone: "",
      abtus_cr_sectionone_artone: "",
      abtus_cr_sectionone_arttwo: "",
      abtus_cr_sectionone_artthree: "",
      abtus_cr_sectionone_artfour: "",
      abtus_cr_sectionone_artfive: "",
      abtus_cr_sectionone_artsix: "",
      abtus_cr_sectiontwo: "",
      abtus_cr_sectiontwo_artone: "",
      abtus_cr_sectiontwo_arttwo: "",
      abtus_cr_sectiontwo_artthree: "",
      abtus_cr_sectiontwo_artfour: "",
      abtus_cr_benefit: "",
      abtus_cr_searchsection: "",
      abtus_cr_searchsection_labelone: "",
      abtus_cr_searchsection_labeltwo: "",
      abtus_cr_searchsection_labelthree: "",
      abtus_cr_searchsection_labelfour: "",
      abtus_cr_searchsection_labelfive: "",
      abtus_cr_searchsection_labelsix: "",
      abtus_cr_searchsection_labeleight: "",
      abtus_cr_searchsection_labelnine: "",
      abtus_cr_benefit_subtit_one: "",
      abtus_cr_benefit_subtit_two: "",
      abtus_cr_benefit_subtit_three: "",
      abtus_cr_benefit_subtit_four: "",
      abtus_cr_benefit_subtit_five: "",
      abtus_cr_benefit_subtit_six: "",
      metaData: "",
    },
    accountsBankAccounts: {
      ac_bnk_mainhead: "",
      ac_bnk_mainhead_txtone: "",
      ac_bnk_mainhead_txttwo: "",
      ac_bnk_mainhead_txtthree: "",
      ac_bnk_mainhead_actionname: "",
      ac_bnk_mainhead_privatereglnk: "",
      ac_bnk_mainhead_businessreglnk: "",
      ac_bnk_sectionone: "",
      ac_bnk_sectiononetxt: "",
      ac_bnk_sectionone_hdonetxt: "",
      ac_bnk_sectionone_hdtwotxt: "",
      ac_bnk_sectionone_hdthreetxt: "",
      ac_bnk_sectiontwo_subhd: "",
      ac_bnk_sectiontwo_listleft_one: "",
      ac_bnk_sectiontwo_listleft_two: "",
      ac_bnk_sectiontwo_listleft_three: "",
      ac_bnk_sectiontwo_listleft_four: "",
      ac_bnk_sectiontwo_listleft_five: "",
      ac_bnk_sectiontwo_listright_one: "",
      ac_bnk_sectiontwo_listright_two: "",
      ac_bnk_sectiontwo_listright_three: "",
      ac_bnk_sectiontwo_listright_four: "",
      ac_bnk_sectionthree_subhd: "",
      ac_bnk_sectionthree_listleft_one: "",
      ac_bnk_sectionthree_listleft_two: "",
      ac_bnk_sectionthree_listleft_three: "",
      ac_bnk_sectionthree_listleft_four: "",
      ac_bnk_sectionthree_listleft_five: "",
      ac_bnk_sectionthree_listleft_six: "",
      ac_bnk_sectionthree_listright_one: "",
      ac_bnk_sectionthree_listright_two: "",
      ac_bnk_sectionthree_listright_three: "",
      ac_bnk_sectionthree_listright_four: "",
      ac_bnk_sectionthree_listright_five: "",
      ac_bnk_sectionthree_listright_six: "",
      ac_bnk_sectionfour_subhd: "",
      ac_bnk_sectionfour_paraone: "",
      ac_fee_lower_drp_dflt_one: "",
      ac_fee_lower_drp_dflt_two: "",
      ac_fee_lower_drp_dflt_three: "",
      ac_fee_lower_drp_dflt_four: "",
      ac_fee_lower_drp_dflt_five: "",
      ac_fee_typslde_subfoot_one: "",
      ac_fee_typslde_subfoot_searchtxt: "",
      ac_fee_typslde_subfoot_searchtxthint: "",
      metaData: "",
    },
    accountsCryptoCurrency: {
      acnt_crypt_mainhead: "",
      acnt_crypt_acnt_opnlink_text: "",
      acnt_crypt_sectionone: "",
      acnt_crypt_sectionone_tbl_colleft_hdone: "",
      acnt_crypt_sectionone_tbl_colleft_txtone: "",
      acnt_crypt_sectionone_tbl_colleft_hdtwo: "",
      acnt_crypt_sectionone_tbl_colleft_txttwo: "",
      acnt_crypt_sectionone_tbl_collmid_hdone: "",
      acnt_crypt_sectionone_tbl_collmid_txtone: "",
      acnt_crypt_sectionone_tbl_collmid_hdtwo: "",
      acnt_crypt_sectionone_tbl_collmid_txttwo: "",
      acnt_crypt_sectionone_tbl_collright_hdone: "",
      acnt_crypt_sectionone_tbl_collright_txtone: "",
      acnt_crypt_sectionone_tbl_collright_hdtwo: "",
      acnt_crypt_sectionone_tbl_collright_txttwo: "",
      acnt_crypt_sectiontwo_txtone: "",
      acnt_crypt_sectiontwo_txttwo: "",
      acnt_crypt_sectiontwo_txttwo_link: "",
      metaData: "",
    },
    accountsFees: {
      ac_card_page_title: "",
      ac_card_grp_a: "",
      ac_card_grp_b: "",
      ac_card_grp_c: "",
      ac_card_grp_d: "",
      ac_card_bnfts_note_a: "",
      ac_card_bnfts_note_b: "",
      ac_card_page_main_title: "",
      ac_fee_hdone: "",
      ac_fee_hdone_sub: "",
      ac_fee_hdone_acc: "",
      ac_fee_typslde_hdone: "",
      ac_fee_typslde_hdone_subhdone: "",
      ac_fee_typslde_hdone_subhdone_pone: "",
      ac_fee_typslde_hdone_subhdtwo_pseven: "",
      ac_fee_typslde_hdone_subhdtwo: "",
      ac_fee_typslde_hdone_subhdtwo_pone: "",
      ac_fee_typslde_hdone_subhdtwo_ptwo: "",
      ac_fee_typslde_hdone_subhdtwo_pthree: "",
      ac_fee_typslde_hdone_subhdthree: "",
      ac_fee_typslde_hdone_subhdthree_pone: "",
      ac_fee_typslde_hdone_subhdthree_ptwo: "",
      ac_fee_typslde_hdone_subhdfour: "",
      ac_fee_typslde_hdone_subhdfour_pone: "",
      ac_fee_typslde_hdone_subhdfour_ptwo: "",
      ac_fee_typslde_hdone_subhdfive: "",
      ac_fee_typslde_hdone_subhdfive_pone: "",
      ac_fee_typslde_hdone_subhdSix: "",
      ac_fee_typslde_hdone_subhdsix_pone: "",
      ac_fee_typslde_hdone_subhdsix_ptwo: "",
      ac_fee_typslde_hdone_subhdsix_pthree: "",
      ac_fee_typslde_hdone_subhdsix_pfour: "",
      ac_fee_typslde_hdone_subhdsix_pfive: "",
      ac_fee_typslde_hdone_subhdsix_psix: "",
      ac_fee_typslde_hdone_subhdsix_pseven: "",
      ac_fee_typslde_hdone_subhdsix_peight: "",
      ac_fee_typslde_hdtwo: "",
      ac_fee_typslde_hdtwo_subhdone: "",
      ac_fee_typslde_hdtwo_subhdone_pone: "",
      ac_fee_typslde_hdtwo_subhdtwo: "",
      ac_fee_typslde_hdtwo_subhdtwo_pone: "",
      ac_fee_typslde_hdtwo_subhdtwo_ptwo: "",
      ac_fee_typslde_hdtwo_subhdtwo_pthree: "",
      ac_fee_typslde_hdtwo_subhdtwo_pfour: "",
      ac_fee_typslde_hdtwo_subhdtwo_pfive: "",
      ac_fee_typslde_hdtwo_subhdtwo_psix: "",
      ac_fee_typslde_hdtwo_subhdtwo_pseven: "",
      ac_fee_typslde_hdtwo_subhdthree: "",
      ac_fee_typslde_hdtwo_subhdthree_pone: "",
      ac_fee_typslde_hdtwo_subhdthree_pthree: "",
      ac_fee_typslde_hdtwo_subhdfour: "",
      ac_fee_typslde_hdtwo_subhdfour_pone: "",
      ac_fee_typslde_hdtwo_subhdfour_pthree: "",
      ac_fee_typslde_hdtwo_subhdfive: "",
      ac_fee_typslde_hdtwo_subhdfive_pone: "",
      ac_fee_typslde_hdtwo_subhdfive_pthree: "",
      ac_fee_typslde_hdtwo_subhdsix: "",
      ac_fee_typslde_hdtwo_subhdSix_pone: "",
      ac_fee_typslde_hdtwo_subhdSix_ptwo: "",
      ac_fee_typslde_hdtwo_subhdSix_pthree: "",
      ac_fee_typslde_hdtwo_subhdSix_pthree_nxt: "",
      ac_fee_typslde_hdtwo_subhdSix_pfour: "",
      ac_fee_typslde_hdtwo_subhdSix_pfour_nxt: "",
      ac_fee_typslde_hdtwo_subhdSix_pfive: "",
      ac_fee_typslde_hdtwo_subhdSix_psix: "",
      ac_fee_typslde_hdthree: "",
      ac_fee_typslde_hdthree_subhdone: "",
      ac_fee_typslde_hdthree_subhdone_pone: "",
      ac_fee_typslde_hdthree_subhdtwo: "",
      ac_fee_typslde_hdthree_subhdtwo_pone: "",
      ac_fee_typslde_hdthree_subhdtwo_ptwo: "",
      ac_fee_typslde_hdthree_subhdtwo_pthree: "",
      ac_fee_typslde_hdthree_subhdtwo_pfour: "",
      ac_fee_typslde_hdthree_subhdtwo_pfive: "",
      ac_fee_typslde_hdthree_subhdtwo_psix: "",
      ac_fee_typslde_hdthree_subhdtwo_pseven: "",
      ac_fee_typslde_hdthree_subhdthree: "",
      ac_fee_typslde_hdthree_subhdthree_pone: "",
      ac_fee_typslde_hdthree_subhdthree_ptwo: "",
      ac_fee_typslde_hdthree_subhdthree_pthree: "",
      ac_fee_typslde_hdthree_subhdfour: "",
      ac_fee_typslde_hdthree_subhdfour_pone: "",
      ac_fee_typslde_hdthree_subhdfour_ptwo: "",
      ac_fee_typslde_hdthree_subhdfive: "",
      ac_fee_typslde_hdthree_subhdfive_pone: "",
      ac_fee_typslde_hdthree_subhdfive_ptwo: "",
      ac_fee_typslde_hdthree_subhdfive_pthree: "",
      ac_fee_typslde_hdthree_subhdsix: "",
      ac_fee_typslde_hdthree_subhdsix_pone: "",
      ac_fee_typslde_hdthree_subhdsix_ptwo: "",
      ac_fee_typslde_hdthree_subhdsix_pthree: "",
      ac_fee_typslde_hdthree_subhdSix_pthree_nxt: "",
      ac_fee_typslde_hdthree_subhdsix_pfour: "",
      ac_fee_typslde_hdthree_subhdsix_pfive: "",
      ac_fee_typslde_hdthree_subhdsix_psix: "",
      ac_fee_typslde_hdthree_subhdSix_pseven: "",
      ac_fee_typslde_hdthree_subhdSix_peight: "",
      ac_fee_typslde_hdfour: "",
      ac_fee_typslde_hdfour_subhdone: "",
      ac_fee_typslde_hdfour_subhdone_pone: "",
      ac_fee_typslde_hdfour_subhdtwo: "",
      ac_fee_typslde_hdfour_subhdtwo_pone: "",
      ac_fee_typslde_hdfour_subhdtwo_ptwo: "",
      ac_fee_typslde_hdfour_subhdtwo_pthree: "",
      ac_fee_typslde_hdfour_subhdtwo_pfour: "",
      ac_fee_typslde_hdfour_subhdtwo_pfive: "",
      ac_fee_typslde_hdfour_subhdtwo_psix: "",
      ac_fee_typslde_hdfour_subhdtwo_pseven: "",
      ac_fee_typslde_hdfour_subhdthree: "",
      ac_fee_typslde_hdfour_subhdthree_pone: "",
      ac_fee_typslde_hdfour_subhdthree_ptwo: "",
      ac_fee_typslde_hdfour_subhdthree_pthree: "",
      ac_fee_typslde_hdfour_subhdthree_pfour: "",
      ac_fee_typslde_hdfour_subhdfour: "",
      ac_fee_typslde_hdfour_subhdfour_pone: "",
      ac_fee_typslde_hdfour_subhdfour_ptwo: "",
      ac_fee_typslde_hdfour_subhdfour_pthree: "",
      ac_fee_typslde_hdfour_subhdfive: "",
      ac_fee_typslde_hdfour_subhdfive_pone: "",
      ac_fee_typslde_hdfour_subhdfive_ptwo: "",
      ac_fee_typslde_hdfour_subhdfive_pthree: "",
      ac_fee_typslde_hdfour_subhdsix: "",
      ac_fee_typslde_hdfour_subhdsix_pone: "",
      ac_fee_typslde_hdfour_subhdsix_ptwo: "",
      ac_fee_typslde_hdfour_subhdsix_pthree: "",
      ac_fee_typslde_hdfour_subhdsix_pfour: "",
      ac_fee_typslde_hdfour_subhdsix_pfive: "",
      ac_fee_typslde_hdfour_subhdsix_pSix: "",
      ac_fee_typslde_hdfour_subhdsix_pseven: "",
      ac_fee_typslde_hdfour_subhdsix_peight: "",
      ac_fee_typslde_hdfour_subhdsix_pnine: "",
      ac_fee_typslde_hdfour_subhdsix_pten: "",
      ac_fee_typslde_hdfour_subhdsix_peleven: "",
      ac_fee_lower_hdone: "",
      ac_fee_btmline: "",
      ac_fee_lower_drp_dflt_one: "",
      ac_fee_lower_drp_dflt_two: "",
      ac_fee_lower_drp_dflt_three: "",
      ac_fee_lower_drp_dflt_four: "",
      ac_fee_lower_drp_dflt_five: "",
      ac_fee_typslde_subfoot_searchtxt: "",
      ac_fee_typslde_subfoot_searchtxthint: "",
      metaData: "",
    },
    mediaCenterInvestorNews: {
      mc_invnws_headone: "",
      mc_invnws_headone_nxt: "",
      metaData: "",
    },
    mediaCenterNews: {
      mc_nws_headone: "",
      mc_nws_headone_txt: "",
      mc_nws_shre_txt: "",
      mc_nws_intrvw_text: "",
      metaData: "",
    },
    mediaCenterBlog: {
      mcn_headone: "",
      metaData: "",
    },
    contactBlackBanx: {
      cus_headone: "",
      cus_cntctus: "",
      cus_qstn: "",
      cus_qstn_nme: "",
      cus_sup_chat: "",
      cus_qstn_eml: "",
      cus_qstn_sntbtn: "",
      cus_city_lndn: "",
      cus_city_mscw: "",
      cus_city_dbai: "",
      cus_city_tnto: "",
      cus_city_cptwn: "",
      cus_city_tkyo: "",
      cus_city_shghi: "",
      cus_sup_inq: "",
      cus_con_prir_inq: "",
      cus_sup_inq_email: "",
      cus_sup_prir_email: "",
      cus_sup_prir_email_nxt: "",
      cus_leave_shortmsg: "",
      cus_sup_chat_sub: "",
      cus_qstn_nme_txtbx: "",
      cus_qstn_eml_txtbx: "",
      cus_qstn_msg_txtbx: "",
      cus_city_saplo: "",
      cus_branch_hd: "",
      cus_branch_subhd: "",
      cus_branch_searchtext: "",
      cus_qstn_nme_error: "",
      cus_qstn_eml_error: "",
      cus_qstn_msg_error: "",
      metaData: "",
    },
    policiesTerms: {
      pt_headone: "",
      pt_headone_sub: "",
      pt_paraone: "",
      pt_paratwo: "",
      pt_subhd_one: "",
      pt_subhd_one_paraone: "",
      pt_subhd_two: "",
      pt_subhd_two_paraone: "",
      pt_subhd_three: "",
      pt_subhd_three_paraone: "",
      pt_subhd_three_paratwo: "",
      pt_subhd_four: "",
      pt_subhd_four_paraone: "",
      pt_subhd_four_paratwo: "",
      pt_subhd_four_parathree: "",
      pt_subhd_five: "",
      pt_subhd_five_paraone: "",
      pt_subhd_six: "",
      pt_subhd_six_paraone: "",
      pt_subhd_seven: "",
      pt_subhd_seven_paraone: "",
      pt_subhd_eight: "",
      pt_subhd_eight_paraone: "",
      pt_subhd_nine: "",
      pt_subhd_nine_paraone: "",
      pt_subhd_ten: "",
      pt_subhd_ten_paraone: "",
      pt_subhd_ten_paratwo: "",
      pt_subhd_eleven: "",
      pt_subhd_eleven_paraone: "",
      pt_subhd_eleven_paratwo: "",
      pt_subhd_eleven_parathree: "",
      pt_subhd_eleven_parafour: "",
      pt_subhd_twelve: "",
      pt_subhd_twelve_paraone: "",
      pt_subhd_thirtn: "",
      pt_subhd_thhirtn_paraone: "",
      pt_subhd_thhirtn_paratwo: "",
      pt_subhd_fourtn: "",
      pt_subhd_fourtn_paraone: "",
      pt_btmline: "",
      metaData: "",
    },
    privacyPolicy: {
      pp_headone: "",
      pp_headone_subhd: "",
      pp_paraone: "",
      pp_subhd_one: "",
      pp_subhd_one_paraone: "",
      pp_subhd_one_paratwo: "",
      pp_subhd_two: "",
      pp_subhd_two_paraone: "",
      pp_subhd_two_paratwo: "",
      pp_subhd_two_parathree: "",
      pp_subhd_three: "",
      pp_subhd_three_paraone: "",
      pp_subhd_three_paratwo: "",
      pp_subhd_three_parathree: "",
      pp_subhd_three_pntone: "",
      pp_subhd_three_pnttwo: "",
      pp_subhd_three_pntthree: "",
      pp_subhd_three_pntfour: "",
      pp_subhd_three_parafour: "",
      pp_subhd_four: "",
      pp_subhd_four_paraone: "",
      pp_subhd_four_paratwo: "",
      pp_subhd_four_parathree: "",
      pp_subhd_four_parafour: "",
      pp_subhd_four_parafive: "",
      pp_subhd_four_parasix: "",
      pp_subhd_five: "",
      pp_subhd_five_paraone: "",
      pp_subhd_five_paratwo: "",
      pp_subhd_five_parathree: "",
      pp_subhd_six: "",
      pp_subhd_six_paraone: "",
      pp_subhd_seven: "",
      pp_subhd_seven_paraone: "",
      pp_subhd_eight: "",
      pp_subhd_eight_paraone: "",
      pp_subhd_eight_paratwo: "",
      pp_subhd_nine: "",
      pp_subhd_nine_paraone: "",
      pp_subhd_ten: "",
      pp_subhd_ten_paraone: "",
      pp_subhd_ten_paraone_pntone: "",
      pp_subhd_ten_paraone_pnttwo: "",
      pp_subhd_ten_paraone_pntthree: "",
      pp_subhd_ten_paratwo: "",
      pp_subhd_ten_paratwo_lineone: "",
      pp_subhd_ten_paratwo_linetwo: "",
      pp_subhd_ten_paratwo_linethree: "",
      pp_subhd_ten_paratwo_linefour: "",
      pp_btmline: "",
      metaData: "",
    },
    codeOfConduct: {
      abtus_coc_mainhead: "",
      abtus_coc_mainhead_nxt: "",
      abtus_coc_mainhead_paraone: "",
      abtus_coc_mainhead_paratwo: "",
      abtus_coc_headone: "",
      abtus_coc_headone_paraone: "",
      abtus_coc_headtwo: "",
      abtus_coc_headtwo_paraone: "",
      abtus_coc_headtwo_hdone: "",
      abtus_coc_headtwo_txtone: "",
      abtus_coc_headtwo_hdtwo: "",
      abtus_coc_headtwo_txttwo: "",
      abtus_coc_headtwo_hdthree: "",
      abtus_coc_headtwo_txtthree: "",
      abtus_coc_headtwo_hdfour: "",
      abtus_coc_headtwo_txtfour: "",
      abtus_coc_headtwo_hdfive: "",
      abtus_coc_headtwo_txtfive: "",
      abtus_coc_headtwo_hdsix: "",
      abtus_coc_headtwo_txtsix: "",
      abtus_coc_headtwo_hdseven: "",
      abtus_coc_headtwo_txtseven: "",
      abtus_coc_headtwo_subheadone: "",
      abtus_coc_headtwo_subheadone_paraone: "",
      abtus_coc_headtwo_subheadone_paratwo: "",
      abtus_coc_headtwo_subheadone_parathree: "",
      abtus_coc_headtwo_subheadone_parafour: "",
      abtus_coc_headtwo_subheadone_parafive: "",
      abtus_coc_headtwo_subheadone_parasix: "",
      abtus_coc_headtwo_subheadone_paraseven: "",
      abtus_coc_headtwo_subheadone_paraeight: "",
      abtus_coc_headtwo_subheadtwo: "",
      abtus_coc_headtwo_subheadtwo_paraone: "",
      abtus_coc_headtwo_subheadthree: "",
      abtus_coc_headtwo_subheadthree_paraone: "",
      abtus_coc_headtwo_subheadthree_paratwo: "",
      abtus_coc_headtwo_subheadthree_parathree: "",
      abtus_coc_headtwo_subheadthree_parafour: "",
      abtus_coc_headtwo_subheadfour: "",
      abtus_coc_headtwo_subheadfour_paraone: "",
      abtus_coc_headtwo_subheadfour_paratwo: "",
      abtus_coc_headtwo_subheadfour_parathree: "",
      abtus_coc_headtwo_subheadfour_parafour: "",
      abtus_coc_headtwo_subheadfive: "",
      abtus_coc_headtwo_subheadfive_paraone: "",
      abtus_coc_headtwo_subheadfive_paratwo: "",
      abtus_coc_headtwo_subheadfive_parathree: "",
      abtus_coc_headtwo_subheadsix: "",
      abtus_coc_headtwo_subheadsix_paraone: "",
      abtus_coc_headtwo_subheadsix_paratwo: "",
      abtus_coc_headtwo_subheadsix_parathree: "",
      abtus_coc_headtwo_subheadseven: "",
      abtus_coc_headtwo_subheadseven_paraone: "",
      abtus_coc_headthree: "",
      abtus_coc_headthree_paraone: "",
      abtus_coc_headthree_paratwo: "",
      abtus_coc_headthree_parathree: "",
      abtus_coc_headthree_subheadone: "",
      abtus_coc_headthree_subheadone_paraone: "",
      abtus_coc_headthree_subheadone_paratwo: "",
      abtus_coc_headthree_subheadone_parathree: "",
      abtus_coc_headthree_subheadtwo: "",
      abtus_coc_headthree_subheadtwo_paraone: "",
      abtus_coc_headthree_subheadtwo_paratwo: "",
      abtus_coc_headthree_subheadtwo_parathree: "",
      abtus_coc_headthree_subheadtwo_parafour: "",
      abtus_coc_headthree_subheadthree: "",
      abtus_coc_headthree_subheadthree_paraone: "",
      abtus_coc_headthree_subheadthree_paratwo: "",
      abtus_coc_headthree_subheadthree_parathree: "",
      abtus_coc_headthree_subheadfour: "",
      abtus_coc_headthree_subheadfour_paraone: "",
      abtus_coc_headthree_subheadfive: "",
      abtus_coc_headthree_subheadfive_paraone: "",
      abtus_coc_headthree_subheadsix: "",
      abtus_coc_headthree_subheadsix_paraone: "",
      abtus_coc_headthree_subheadseven: "",
      abtus_coc_headthree_subheadseven_paraone: "",
      abtus_coc_headthree_subheadseven_paratwo: "",
      abtus_coc_headthree_subheadseven_parathree: "",
      abtus_coc_headthree_subheadseven_parafour: "",
      abtus_coc_headthree_subheadseven_parafive: "",
      abtus_coc_headthree_subheadseven_parasix: "",
      abtus_coc_headthree_subheadseven_paraseven: "",
      abtus_coc_headthree_subheadeight: "",
      abtus_coc_headthree_subheadeight_paraone: "",
      abtus_coc_headthree_subheadeight_paratwo: "",
      abtus_coc_headthree_subheadeight_parathree: "",
      abtus_coc_headthree_subheadeight_parafour: "",
      abtus_coc_headfour: "",
      abtus_coc_headfive: "",
      abtus_coc_headfive_paraone: "",
      abtus_coc_headfive_paratwo: "",
      abtus_coc_headfive_parathree: "",
      abtus_coc_headsix: "",
      abtus_coc_headsix_paraone: "",
      abtus_coc_headseven: "",
      abtus_coc_headseven_paraone: "",
      abtus_coc_headseven_paratwo: "",
      abtus_coc_headseven_parathree: "",
      abtus_coc_headeight: "",
      abtus_coc_headeight_paraone: "",
      abtus_coc_headeight_paratwo: "",
      abtus_coc_headnine: "",
      abtus_coc_headnine_paraone: "",
      abtus_coc_headten: "",
      abtus_coc_headten_paraone: "",
      abtus_coc_headten_paratwo: "",
      abtus_coc_headten_parathree: "",
      abtus_coc_headten_parafour: "",
      metaData: "",
    },
    culture: {
      abtus_cult_mainhead: "",
      abtus_cult_mainhead_nxt: "",
      abtus_cult_mainhead_paraone: "",
      abtus_cult_mainhead_paratwo: "",
      abtus_cult_mainhead_parathree: "",
      abtus_cult_mainhead_parafour: "",
      abtus_cult_headone: "",
      abtus_cult_headone_subheadone: "",
      abtus_cult_headone_subheadone_paraone: "",
      abtus_cult_headone_subheadtwo: "",
      abtus_cult_headone_subheadtwo_paraone: "",
      abtus_cult_headone_subheadthree: "",
      abtus_cult_headone_subheadtwo_parathree: "",
      abtus_cult_headone_subheadfour: "",
      abtus_cult_headone_subheadtwo_parafour: "",
      metaData: "",
    },
    governance: {
      abtus_gvrn_mainhead: "",
      abtus_gvrn_mainhead_nxt: "",
      abtus_gvrn_mainhead_paraone: "",
      abtus_gvrn_mainhead_paratwo: "",
      abtus_gvrn_headone: "",
      abtus_gvrn_headone_paraone: "",
      abtus_gvrn_headone_paratwo: "",
      abtus_gvrn_headone_parathree: "",
      abtus_gvrn_headtwo: "",
      abtus_gvrn_headtwo_paraone: "",
      abtus_gvrn_headtwo_paratwo: "",
      abtus_gvrn_headtwo_parathree: "",
      abtus_gvrn_headthree: "",
      abtus_gvrn_headthree_paraone: "",
      abtus_gvrn_headthree_paratwo: "",
      abtus_gvrn_headthree_parathree: "",
      abtus_gvrn_headthree_parafour: "",
      abtus_gvrn_headthree_parafive: "",
      abtus_gvrn_headfour: "",
      abtus_gvrn_headfour_paraone: "",
      abtus_gvrn_headfour_paratwo: "",
      abtus_gvrn_headfour_parathree: "",
      abtus_gvrn_headfour_parafour: "",
      abtus_gvrn_headfive: "",
      abtus_gvrn_headfive_paraone: "",
      abtus_gvrn_headfive_paratwo: "",
      abtus_gvrn_headfive_chartheadone: "",
      abtus_gvrn_headfive_chartheadone_leafone: "",
      abtus_gvrn_headfive_chartheadone_leaftwo: "",
      abtus_gvrn_headfive_chartheadone_leafthree: "",
      abtus_gvrn_headfive_chartheadone_leaffour: "",
      abtus_gvrn_headfive_hdone: "",
      abtus_gvrn_headfive_txtone: "",
      abtus_gvrn_headfive_hdtwo: "",
      abtus_gvrn_headfive_txttwo: "",
      abtus_gvrn_headfive_hdthree: "",
      abtus_gvrn_headfive_txtthree: "",
      abtus_gvrn_headfive_hdfour: "",
      abtus_gvrn_headfive_txtfour: "",
      abtus_gvrn_headsix: "",
      abtus_gvrn_headsix_paraone: "",
      abtus_gvrn_headsix_paratwo: "",
      abtus_gvrn_headseven: "",
      abtus_gvrn_headseven_paraone: "",
      abtus_gvrn_headseven_paratwo: "",
      abtus_gvrn_headseven_parathree: "",
      abtus_gvrn_headseven_parafour: "",
      abtus_gvrn_headseven_parafive: "",
      abtus_gvrn_headeight: "",
      abtus_gvrn_headeight_paraone: "",
      abtus_gvrn_headeight_paratwo: "",
      abtus_gvrn_headeight_parathree: "",
      abtus_gvrn_headeight_parafour: "",
      abtus_gvrn_headeight_parafive: "",
      abtus_gvrn_headnine: "",
      abtus_gvrn_headnine_paraone: "",
      abtus_gvrn_headnine_paratwo: "",
      abtus_gvrn_headnine_parathree: "",
      abtus_gvrn_headten: "",
      abtus_gvrn_headten_paraone: "",
      abtus_gvrn_headten_lefthead_rowone: "",
      abtus_gvrn_headten_lefthead_rowonetxt: "",
      abtus_gvrn_headten_midheadone_rowone: "",
      abtus_gvrn_headten_midheadone_rowonetxt: "",
      abtus_gvrn_headten_midheadtwo_rowone: "",
      abtus_gvrn_headten_midheadtwo_rowonetxt: "",
      abtus_gvrn_headten_righthead_rowone: "",
      abtus_gvrn_headten_righthead_rowonetxt: "",
      abtus_gvrn_headten_lefthead_rowtwo: "",
      abtus_gvrn_headten_lefthead_rowtwotxt: "",
      abtus_gvrn_headten_midheadone_rowtwo: "",
      abtus_gvrn_headten_midheadone_rowtwotxt: "",
      abtus_gvrn_headten_midheadtwo_rowtwo: "",
      abtus_gvrn_headten_midheadtwo_rowtwotxt: "",
      abtus_gvrn_headeleven: "",
      abtus_gvrn_headeleven_paraone: "",
      abtus_gvrn_headtwelve: "",
      abtus_gvrn_headtwelve_paraone: "",
      abtus_gvrn_headthirteen: "",
      abtus_gvrn_headthirteen_paraone: "",
      abtus_gvrn_headfourteen: "",
      abtus_gvrn_headfourteen_paraone: "",
      abtus_gvrn_headfifteen: "",
      abtus_gvrn_headfifteen_paraone: "",
      abtus_gvrn_headsixteen: "",
      abtus_gvrn_headsixteen_paraone: "",
      abtus_gvrn_headsixteen_paratwo: "",
      abtus_gvrn_headsixteen_parathree: "",
      abtus_gvrn_headseventeen: "",
      abtus_gvrn_headseventeen_paraone: "",
      abtus_gvrn_headseventeen_paratwo: "",
      abtus_gvrn_headeighteen: "",
      abtus_gvrn_headeighteen_paraone: "",
      abtus_gvrn_headeighteen_paratwo: "",
      abtus_gvrn_headeighteen_parathree: "",
      abtus_gvrn_headeighteen_parafour: "",
      abtus_gvrn_headeighteen_parafour_subtxtone: "",
      abtus_gvrn_headeighteen_parafour_subtxttwo: "",
      abtus_gvrn_headeighteen_parafour_subtxtthree: "",
      abtus_gvrn_headeighteen_parafive: "",
      abtus_gvrn_headeighteen_parasix: "",
      abtus_gvrn_headeighteen_paraseven: "",
      abtus_gvrn_site_grp: "",
      abtus_gvrn_site_grp_title: "",
      abtus_gvrn_bst: "",
      abtus_gvrn_bst_grp_name: "",
      abtus_gvrn_bst_grp_place: "",
      abtus_gvrn_bst_head_pct: "",
      abtus_gvrn_bst_grpOne_orgOne_name: "",
      abtus_gvrn_bst_grpOne_orgOne_place: "",
      abtus_gvrn_bst_grpOne_orgTwo_name: "",
      abtus_gvrn_bst_grpOne_orgTwo_place: "",
      abtus_gvrn_bst_grpOne_orgThree_name: "",
      abtus_gvrn_bst_grpOne_orgThree_place: "",
      abtus_gvrn_bst_grpOne_orgFour_name: "",
      abtus_gvrn_bst_grpOne_orgFour_place: "",
      abtus_gvrn_bst_grpTwo_orgOne_name: "",
      abtus_gvrn_bst_grpTwo_orgOne_place: "",
      abtus_gvrn_bst_grpTwo_orgTwo_name: "",
      abtus_gvrn_bst_grpTwo_orgTwo_place: "",
      abtus_gvrn_bst_grpTwo_orgThree_name: "",
      abtus_gvrn_bst_grpTwo_orgThree_place: "",
      abtus_gvrn_bst_grpTwo_orgFour_name: "",
      abtus_gvrn_bst_grpTwo_orgFour_place: "",
      abtus_gvrn_bst_grpThree_orgOne_name: "",
      abtus_gvrn_bst_grpThree_orgOne_place: "",
      abtus_gvrn_bst_grpThree_orgTwo_name: "",
      abtus_gvrn_bst_grpThree_orgTwo_place: "",
      abtus_gvrn_bst_grpThree_orgThree_name: "",
      abtus_gvrn_bst_grpThree_orgThree_place: "",
      abtus_gvrn_bst_grpThree_orgFour_name: "",
      abtus_gvrn_bst_grpThree_orgFour_place: "",
      abtus_gvrn_bst_grpFour_orgOne_name: "",
      abtus_gvrn_bst_grpFour_orgOne_place: "",
      abtus_gvrn_bst_grpFour_orgTwo_name: "",
      abtus_gvrn_bst_grpFour_orgTwo_place: "",
      abtus_gvrn_bst_grpFive_orgOne_name: "",
      abtus_gvrn_bst_grpFive_orgOne_place: "",
      abtus_gvrn_bst_grpFive_orgTwo_name: "",
      abtus_gvrn_bst_grpFive_orgTwo_place: "",
      abtus_gvrn_bst_grpFive_orgThree_name: "",
      abtus_gvrn_bst_grpFive_orgThree_place: "",
      abtus_gvrn_bst_grpFive_orgFour_name: "",
      abtus_gvrn_bst_grpFive_orgFour_place: "",
      metaData: "",
    },
    leadership: {
      abtus_ldrshp_mainhead: "",
      abtus_ldrshp_mainhead_nxt: "",
      abtus_ldr_grp_audit_cmte_chair_lbl: "",
      abtus_ldr_grp_audit_cmte_chair: "",
      abtus_ldr_grp_ncg_cmte_chair: "",
      abtus_ldr_grp_audit_cmte_mbr_one: "",
      abtus_ldr_grp_audit_cmte_mbr_two: "",
      abtus_ldr_grp_audit_cmte_mbr_lbl: "",
      abtus_ldr_grp_audit_cmte: "",
      abtus_ldr_grp_risk_cmte: "",
      abtus_ldr_grp_risk_cmte_chair_lbl: "",
      abtus_ldr_grp_risk_cmte_chair: "",
      abtus_ldr_grp_risk_cmte_mbr_lbl: "",
      abtus_ldr_grp_risk_cmte_mbr_one: "",
      abtus_ldr_grp_risk_cmte_mbr_two: "",
      abtus_ldr_grp_rmnrtn_cmte: "",
      abtus_ldr_grp_rmnrtn_cmte_chair_lbl: "",
      abtus_ldr_grp_rmnrtn_cmte_chair: "",
      abtus_ldr_grp_rmnrtn_cmte_mbr_lbl: "",
      abtus_ldr_grp_rmnrtn_cmte_mbr_one: "",
      abtus_ldr_grp_rmnrtn_cmte_mbr_two: "",
      abtus_ldr_grp_ncg_cmte: "",
      abtus_ldr_grp_ncg_cmte_chair_lbl: "",
      abtus_ldr_grp_ncg_cmte_mbr_lbl: "",
      abtus_ldr_grp_ncg_cmte_mbr_one: "",
      abtus_ldr_grp_ncg_cmte_mbr_two: "",
      abtus_ldr_grp_ncg_cmte_mbr_three: "",
      abtus_ldr_grp_brd: "",
      abtus_ldr_grp_brd_grp_chairman: "",
      abtus_ldr_grp_brd_grp_chairman_note: "",
      abtus_ldr_grp_brd_grp_ce: "",
      abtus_ldr_grp_brd_grp_cfo: "",
      abtus_ldr_grp_brd_grp_cscgo: "",
      abtus_ldr_grp_brd_non_exec_dir_one: "",
      abtus_ldr_sr_exec_tm: "",
      abtus_ldr_sr_exec_tm_grp_ce: "",
      abtus_ldr_reg_brd: "",
      abtus_ldr_reg_exec_tm: "",
      abtus_ldr_reg_brd_grpOne_mbr_one: "",
      abtus_ldr_reg_brd_grpOne_mbr_two: "",
      abtus_ldr_reg_brd_grpTwo_mbr_one: "",
      abtus_ldr_reg_brd_grpTwo_mbr_two: "",
      abtus_ldr_reg_brd_grpThree_mbr_one: "",
      abtus_ldr_reg_brd_grpThree_mbr_two: "",
      abtus_ldr_reg_brd_grpFour_mbr_one: "",
      abtus_ldr_reg_brd_grpFour_mbr_two: "",
      abtus_ldr_reg_brd_grpFive_mbr_one: "",
      abtus_ldr_reg_brd_grpFive_mbr_two: "",
      abtus_ldr_reg_exec_tm_grpOne_ceo: "",
      abtus_ldr_reg_exec_tm_grpOne_cfo: "",
      abtus_ldr_reg_exec_tm_grpOne_cco: "",
      abtus_ldr_reg_exec_tm_grpTwo_ceo: "",
      abtus_ldr_reg_exec_tm_grpTwo_cfo: "",
      abtus_ldr_reg_exec_tm_grpTwo_cco: "",
      abtus_ldr_reg_exec_tm_grpThree_ceo: "",
      abtus_ldr_reg_exec_tm_grpThree_cfo: "",
      abtus_ldr_reg_exec_tm_grpThree_cco: "",
      abtus_ldr_reg_exec_tm_grpFour_ceo: "",
      abtus_ldr_reg_exec_tm_grpFour_cfo: "",
      abtus_ldr_reg_exec_tm_grpFour_cco: "",
      abtus_ldr_reg_exec_tm_grpFive_ceo: "",
      abtus_ldr_reg_exec_tm_grpFive_cfo: "",
      abtus_ldr_reg_exec_tm_grpFive_cco: "",
      abtus_ldr_local_exec_tm_grpOne_ceo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpOne_cfo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpOne_cco_cntryOne: "",
      abtus_ldr_local_exec_tm_grpOne_ceo_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpOne_cfo_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpOne_cco_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpOne_ceo_cntryThree: "",
      abtus_ldr_local_exec_tm_grpOne_cfo_cntryThree: "",
      abtus_ldr_local_exec_tm_grpOne_cco_cntryThree: "",
      abtus_ldr_local_exec_tm_grpTwo_ceo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpTwo_cfo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpTwo_cco_cntryOne: "",
      abtus_ldr_local_exec_tm_grpTwo_ceo_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpTwo_cfo_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpTwo_cco_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpTwo_ceo_cntryThree: "",
      abtus_ldr_local_exec_tm_grpTwo_cfo_cntryThree: "",
      abtus_ldr_local_exec_tm_grpTwo_cco_cntryThree: "",
      abtus_ldr_local_exec_tm_grpThree_ceo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpThree_cfo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpThree_cco_cntryOne: "",
      abtus_ldr_local_exec_tm_grpThree_ceo_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpThree_cfo_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpThree_cco_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpThree_ceo_cntryThree: "",
      abtus_ldr_local_exec_tm_grpThree_cfo_cntryThree: "",
      abtus_ldr_local_exec_tm_grpThree_cco_cntryThree: "",
      abtus_ldr_local_exec_tm_grpFour_ceo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpFour_cfo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpFour_cco_cntryOne: "",
      abtus_ldr_local_exec_tm_grpFive_ceo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpFive_cfo_cntryOne: "",
      abtus_ldr_local_exec_tm_grpFive_cco_cntryOne: "",
      abtus_ldr_local_exec_tm_grpFive_ceo_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpFive_cfo_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpFive_cco_cntryTwo: "",
      abtus_ldr_local_exec_tm_grpFive_ceo_cntryThree: "",
      abtus_ldr_local_exec_tm_grpFive_cfo_cntryThree: "",
      abtus_ldr_local_exec_tm_grpFive_cco_cntryThree: "",
      abtus_ldr_sr_exec_tm_grp_chro: "",
      abtus_ldr_sr_exec_tm_grp_cro: "",
      abtus_ldr_sr_exec_tm_grp_hia: "",
      abtus_ldr_sr_exec_tm_grp_clo: "",
      abtus_ldr_sr_exec_tm_grp_cfo: "",
      abtus_ldr_sr_exec_tm_grp_coo: "",
      abtus_ldr_sr_exec_tm_grp_cco: "",
      abtus_ldr_sr_exec_tm_grp_ccomo: "",
      abtus_ldr_sr_exec_tm_grp_cso: "",
      abtus_ldr_sr_exec_tm_grp_scgo: "",
      abtus_ldr_sr_exec_tm_cebic: "",
      abtus_ldr_sr_exec_tm_cepc: "",
      abtus_ldr_grp_at_cmte_mbr_one: "",
      abtus_ldr_grp_audit_cmte_mudibr_two: "",
      abtus_ldr_local_exec_tm: "",
      abtus_ldrshp_sectionOne: "",
      metaData: "",
    },
    riskManagement: {
      abtus_rism_mainhead: "",
      abtus_rism_mainhead_nxt: "",
      abtus_rism_mainhead_paraone: "",
      abtus_rism_headone: "",
      abtus_rism_headone_paraone: "",
      abtus_rism_headone_paratwo: "",
      abtus_rism_headone_parathree: "",
      abtus_rism_headtwo: "",
      abtus_rism_headtwo_paraone: "",
      abtus_rism_headtwo_paratwo: "",
      abtus_rism_headtwo_parathree: "",
      abtus_rism_headthree: "",
      abtus_rism_headthree_paraone: "",
      abtus_rism_headthree_paratwo: "",
      abtus_rism_headthree_parathree: "",
      abtus_rism_headfour: "",
      abtus_rism_headfour_paraone: "",
      abtus_rism_headfour_paratwo: "",
      abtus_rism_headfour_parathree: "",
      abtus_rism_headfive: "",
      abtus_rism_headfive_paraone: "",
      abtus_rism_headfive_paratwo: "",
      abtus_rism_headsix: "",
      abtus_rism_headsix_paraone: "",
      abtus_rism_headsix_paratwo: "",
      abtus_rism_headseven: "",
      abtus_rism_headseven_paraone: "",
      abtus_rism_headseven_paratwo: "",
      abtus_rism_headeight: "",
      abtus_rism_headeight_paraone: "",
      abtus_rism_headeight_paratwo: "",
      abtus_rism_headeight_parathree: "",
      metaData: "",
    },
    sustainabilityCareers: {
      abtus_sust_headone: "",
      abtus_sust_headone_paraone: "",
      abtus_sust_headone_paratwo: "",
      abtus_sust_headone_subheadone: "",
      abtus_sust_headone_subheadone_paraone: "",
      abtus_sust_headone_subheadtwo: "",
      abtus_sust_headone_subheadtwo_paraone: "",
      abtus_sust_headone_subheadthree: "",
      abtus_sust_headone_subheadtwo_parathree: "",
      abtus_sust_headone_parathree: "",
      abtus_sust_headone_parafour: "",
      abtus_sust_headone_parafive: "",
      abtus_sust_headone_parasix: "",
      abtus_sust_headtwo: "",
      abtus_sust_headtwo_paraone: "",
      abtus_sust_headtwo_paratwo: "",
      abtus_sust_headtwo_columnhead_one: "",
      abtus_sust_headtwo_columnheadtxt_one: "",
      abtus_sust_headtwo_columnheadtxt_two: "",
      abtus_sust_headtwo_columnheadtxt_three: "",
      abtus_sust_headtwo_columnheadtxt_four: "",
      abtus_sust_headtwo_parathree: "",
      abtus_sust_headtwo_parafour: "",
      abtus_sust_headthree: "",
      abtus_sust_headthree_subheadone: "",
      abtus_sust_headthree_subheadone_next: "",
      abtus_sust_headthree_subheadone_paraone: "",
      abtus_sust_headthree_subheadone_paraone_next: "",
      abtus_sust_headthree_subheadone_paratwo: "",
      abtus_sust_headthree_scopeone_txt: "",
      abtus_sust_headthree_scopetwo_txt: "",
      abtus_sust_headthree_subheadtwo: "",
      //abtus_sust_headthree_subheadtwo: "",
      abtus_sust_headthree_subheadtwo_paraone: "",
      abtus_sust_headthree_subheadtwo_parathree: "",
      abtus_sust_headthree_tbl_colleft: "",
      abtus_sust_headthree_tbl_collefttxt: "",
      abtus_sust_headthree_tbl_colmidone: "",
      abtus_sust_headthree_tbl_colmidone_txtone: "",
      abtus_sust_headthree_tbl_colmidone_txttwo: "",
      abtus_sust_headthree_tbl_colmidone_txtthree: "",
      abtus_sust_headthree_tbl_colmidone_txtfour: "",
      abtus_sust_headthree_tbl_colmidone_txtfive: "",
      abtus_sust_headthree_tbl_colrightone: "",
      abtus_sust_headthree_tbl_colrightone_txtone: "",
      abtus_sust_headthree_tbl_colrightone_txttwo: "",
      abtus_sust_headthree_tbl_colrightone_txtthree: "",
      abtus_sust_headthree_tbl_colrightone_txtfour: "",
      abtus_sust_headthree_tbl_colrightone_txtfive: "",
      abtus_sust_headfour: "",
      abtus_sust_headfour_paraone: "",
      abtus_sust_headfour_subheadone: "",
      abtus_sust_headfour_subheadone_paraone: "",
      abtus_sust_headfour_subheadtwo: "",
      abtus_sust_headfour_subheadtwo_paraone: "",
      abtus_sust_headfour_subheadthree: "",
      abtus_sust_headfour_subheadthree_paraone: "",
      abtus_sust_headfour_subheadfour: "",
      abtus_sust_headfour_subheadfour_paraone: "",
      abtus_sust_headfour_subheadfive: "",
      abtus_sust_headfour_subheadfive_paraone: "",
      abtus_sust_headfour_subheadsix: "",
      abtus_sust_headfour_subheadsix_paraone: "",
      abtus_sust_headfour_subheadseven: "",
      abtus_sust_headfour_subheadseven_paraone: "",
      abtus_sust_headfour_subheadeight: "",
      abtus_sust_headfour_subheadeight_paraone: "",
      abtus_sust_headfour_subheadeight_paratwo: "",
      abtus_sust_headfour_subheadnine: "",
      abtus_sust_headfour_subheadnine_paraone: "",
      abtus_sust_mainhead: "",
      abtus_sust_mainhead_nxt: "",
      abtus_sust_headthree_subheadtwo_paratwo: "",
      metaData: "",
    },
    financialStatements: {
      abtus_finst_mainhead: "",
      abtus_finst_listone_hd: "",
      abtus_finst_list_txtone: "",
      abtus_finst_list_txttwo: "",
      abtus_finst_list_txtthree: "",
      abtus_finst_list_txtfour: "",
      abtus_finst_report_colfour: "",
      abtus_finst_report_colfour_rtf: "",
      abtus_finst_report_colfour_xls: "",
      abtus_finst_listtwo_hd: "",
      abtus_finst_tbl_hdone: "",
      abtus_finst_tbl_hdtwo: "",
      abtus_finst_tbl_hdthree: "",
      abtus_finst_tbl_hdfour: "",
      abtus_finst_report_ftone: "",
      abtus_finst_report_fttwo: "",
      abtus_finst_model_title: "",
      abtus_finst_model_userfield: "",
      abtus_finst_model_user_placeholder: "",
      abtus_finst_model_passwordfield: "",
      abtus_finst_model_password_placeholder: "",
      metaData: "",
    },
    complaintsPolicy: {
      cp_sitename: "",
      cp_mainhead: "",
      cp_page_head: "",
      cp_paraOne: "",
      cp_paraTwo: "",
      cp_paraThree: "",
      cp_paraFour: "",
      metaData: "",
    },
    slaveryPolicy: {
      msp_sitename: "",
      msp_mainhead: "",
      msp_page_head: "",
      msp_page_desc_one: "",
      msp_page_desc_two: "",
      msp_secOne: "",
      msp_secOne_paraOne: "",
      msp_secTwo: "",
      msp_secTwo_paraOne: "",
      msp_secThree: "",
      msp_secThree_paraOne: "",
      msp_secFour: "",
      msp_secFour_paraOne: "",
      msp_secFour_paraTwo: "",
      msp_secFour_paraTwo_pointOne: "",
      msp_secFour_paraTwo_pointTwo: "",
      msp_secFour_paraTwo_pointThree: "",
      msp_secFour_paraTwo_pointFour: "",
      msp_secFive: "",
      msp_secFive_paraOne: "",
      msp_secFive_paraOne_pointOne: "",
      msp_secFive_paraOne_pointTwo: "",
      msp_secFive_paraOne_pointThree: "",
      msp_secSix_paraTwo: "",
      msp_secSeven: "",
      msp_secSeven_paraOne: "",
      msp_secSeven_paraTwo: "",
      msp_secEight: "",
      msp_secEight_paraOne: "",
      msp_secEight_paraTwo: "",
      msp_secEight_paraThree: "",
      msp_secNine: "",
      msp_secNine_paraOne: "",
      metaData: "",
    },
  },
  aboutUsLeadership: null,
  aboutUsLeadershipPerson: null,
  aboutUsFinanStats: null,
  aboutUsFinanDropDown: null,
  careerJobs: null,
  cardBenefits: null,
};

const uiDataSlice = createSlice({
  name: "uiData",
  initialState,
  reducers: {
    setUiDataFetching: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      if (action.payload.id) {
        state.selectedLanguageId = action.payload.id;
      }
    },
    setUsLeadershipPerson: (state, action) => {
      state.aboutUsLeadershipPerson = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(operations.getLanguages.fulfilled, (state, action) => {
        state.languageList = action.payload.map((el) => ({
          id: el.language_id,
          label: el.language,
          value: el.language.toUpperCase(),
          abbr: el.language.toUpperCase(),
          // value: el.language,
          // abbr: el.language_code.toUpperCase(),
          endIcon: el.country_flag,
          iso: el.language_code.toLowerCase()
        }));
      })
      .addCase(operations.getCountryOfResidence.fulfilled, (state, action) => {
        state.countryOfResidence = action.payload;
      })
      .addCase(operations.getGlobalOfficeDropDown.fulfilled, (state, action) => {
        state.globalOfficeDropDown = action.payload;
      })
      .addCase(operations.getScripts.fulfilled, (state, action) => {
        state.scripts = action.payload;
      })
      .addCase(operations.getGlobalOffices.fulfilled, (state, action) => {
        state.globalOffices = action.payload.map((el: any) => ({
          id: el.id,
          contact_no: el.contact_no,
          email: el.email,
          office_address: Base64.decode(el["office_address "]),
          country: Base64.decode(el.country),
          zip: Base64.decode(el.zip),
          city: Base64.decode(el.city),
        }));
      })
      .addCase(operations.getCareerDropDown.fulfilled, (state, action) => {
        const select: Record<string, SelectOptions> = {};
        const [payload] = action.payload;
        Object.entries(payload).forEach(([tab, options]) => {
          const option: SelectOptions = [];
          Object.entries(options).forEach(([key, val]) => {
            option.push({ id: key, label: val, value: val });
          });
          select[tab] = option;
        });
        state.careerDropDown = select;
      })
      .addCase(operations.getMediaCenterInvestorNews.fulfilled, (state, action) => {
        //@ts-ignore
        state.mediaCenterInvestorNewsData = action.payload.data.DATA.map((el: SiteContentMediaCenterInvestorNews) => ({
          id: el.mc_invst_id,
          date: Base64.decode(el.mc_invnws_date),
          description: Base64.decode(el.mc_invnws_paraone),
          title: Base64.decode(el.mc_invnws_link_txt),
          img: el.mc_invnws_img,
          link: Base64.decode(el.mc_invnws_link),
          type: Base64.decode(el.mc_invnws_link_type),
          twitter: Base64.decode(el.mc_invnws_twitter),
          linkedin: Base64.decode(el.mc_invnws_linkedin),
        }));
      })
      .addCase(operations.getMediaCenterNews.fulfilled, (state, action) => {
        state.mediaCenterNewsData = action.payload.data.DATA.map((el: SiteContentMediaCenterNews) => {
          const object: any = {};
          Object.keys(el)
            .filter((elem: any) => elem !== "mc_news_id" && elem !== "mc_nws_img")
            //@ts-ignore
            .forEach((element: any) => (object[element] = Base64.decode(el[element])));
          return { mc_news_id: el.mc_news_id, mc_nws_img: el.mc_nws_img, ...object };
        });
      })

      .addCase(operations.getAboutUsLeadership.fulfilled, (state, action) => {
        if (action.payload) {
          let leadershipData: LeadershipAllData = { director_prfle_data: [], executive_prfle_data: [] };
          if (action.payload.director_prfle_data?.length > 1) {
            let director = action.payload.director_prfle_data.map((el: LeadershipBoardData) => {
              const object: any = {};
              Object.keys(el)
                //@ts-ignore
                .forEach((element: any) => (object[element] = Base64.decode(el[element])));
              return { ...object };
            });
            leadershipData.director_prfle_data = director;
          }
          if (action.payload.executive_prfle_data?.length > 1) {
            let executive = action.payload.executive_prfle_data.map((el: LeadershipExecutiveData) => {
              const object: any = {};
              Object.keys(el)
                //@ts-ignore
                .forEach((element: any) => (object[element] = Base64.decode(el[element])));
              return { ...object };
            });
            leadershipData.executive_prfle_data = executive;
          }

          state.aboutUsLeadership = leadershipData;
        }
      })
      .addCase(operations.getAboutUsFinanStats.fulfilled, (state, action) => {
        state.aboutUsFinanStats = action.payload.DATA.map((el: AboutUsFinanStats) => {
          const object: any = {};
          Object.keys(el)
            .filter((elem: any) => elem !== "abtus_findata_id" && elem !== "abtus_lst_id")
            //@ts-ignore
            .forEach((element: any) => (object[element] = Base64.decode(el[element])));
          return { abtus_lst_id: el.abtus_lst_id, abtus_findata_id: el.abtus_findata_id, ...object };
        });
      })
      .addCase(operations.getAboutUsFinanDropDown.fulfilled, (state, action) => {
        const finanDropDown = action.payload.data[0];
        const filings_drop_data = Object.entries(finanDropDown.filings_drop_data).map(([key, val]: any) => {
          return `${Base64.decode(val)}`;
        });
        const year_drop_data = Object.entries(finanDropDown.year_drop_data).map(([key, val]: any) => {
          return `${Base64.decode(val)}`;
        });
        state.aboutUsFinanDropDown = {
          filings_drop_data: filings_drop_data,
          year_drop_data: year_drop_data,
        };
      })
      .addCase(operations.getCareerJobs.fulfilled, (state, action) => {
        state.careerJobs = action.payload.data.map((el: CareerJobs) => {
          const object: any = {};
          Object.keys(el)
            .filter((elem: any) => elem !== "jid")
            //@ts-ignore
            .forEach((element: any) => (object[element] = Base64.decode(el[element])));
          return { jid: el.jid, ...object };
        });
      })
      .addCase(operations.getCardBenefitData.fulfilled, (state, action) => {
        state.cardBenefits = action.payload.data.map((el: CardBenefitData) => {
          const object: any = {};
          Object.keys(el)
            //@ts-ignore
            .forEach((element: any) => (object[element] = Base64.decode(el[element])));
          return { ...object };
        });
      })
      .addCase(operations.getAllWebsiteText.fulfilled, (state, action) => {
        const value: WebsiteTextType = _.cloneDeep(initialState.websiteText);
        action.payload.forEach((el) => {
          const { tab, data, meta } = el;
          //@ts-ignore
          try {
            value[tab].metaData = Base64.decode(meta);
            Object.entries(data).forEach(([key, val]) => {
              //@ts-ignore
              value[tab][key] = Base64.decode(val);
            });
          } catch (error) {
            console.log("ERROR WHEN TRY TO PARSE", tab, error);
          }
        });
        state.websiteText = value;
      });
  },
});

export const { setUiDataFetching, setSelectedLanguage, setUsLeadershipPerson } = uiDataSlice.actions;
export default uiDataSlice.reducer;
