import styled from "styled-components";
import { media } from "../../utils/use-media-query";
const PoliciesHeaderRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 6rem;
  text-align: center;
  @media (${media.sm}) {
     {
      margin-bottom: 0rem;
    }
  }
`;

const PoliciesHeaderTitle = styled.h3`
  @media (${media.sm}) {
     {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      text-align: left;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
`;
const PoliciesHeaderSubTitle = styled.span`
  @media (${media.sm}) {
     {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      text-align: left;
    }
  }
`;

const PoliciesTextRoot = styled.div`
  margin: 1.5rem auto;
  width: 100%;
  &:last-child {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
`;

const PoliciesText = styled.p`
  text-align: justify;
  @media (${media.sm}) {
     {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #bdbdbd;
    }
  }
`;

const BodyTitle = styled.h4`
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
  margin-bottom: 1.6rem;
  @media (${media.sm}) {
     {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      text-align: left;
    }
  }
`;

const ListRoot = styled.ul`
  margin-bottom: 3rem;
`;
const ListItem = styled.li``;

export const Policies = {
  Header: {
    Root: PoliciesHeaderRoot,
    Title: PoliciesHeaderTitle,
    SubTitle: PoliciesHeaderSubTitle,
  },
  Body: {
    Root: PoliciesTextRoot,
    Text: PoliciesText,
    Title: BodyTitle,
  },
  List: {
    Root: ListRoot,
    Item: ListItem,
  },
};
